const firebaseConfig = {
  apiKey: "AIzaSyAL5Va51cB2_zkhZsK8rj3zs00zs4mrYDs",
  authDomain: "quixote-club.firebaseapp.com",
  projectId: "quixote-club",
  storageBucket: "quixote-club.appspot.com",
  messagingSenderId: "58929229940",
  appId: "1:58929229940:web:80f94bea53b47494bda121",
  measurementId: "G-5BWKFV5E76"
};
  
export default firebaseConfig;